.career-spotlight {
  background-color: #2BE3E8;
  padding:80px 0px;
  z-index: 3;
  position: relative;
  h2 {
    color: #4D4A4D;
    font-size: 48px;
    line-height: 53px;
    margin:60px 0px 25px 0px;
    font-weight: 300;//400
  }
  .box {
    background:white;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);
    padding:50px 80px;
    h3 {
      font-weight:700;
      a{
        color: #4d4a4d !important;
      }
    }
    h4,p {
      font-size: 18px;
      line-height: 26px;
    }
    h4 {
      font-weight:700;
    }
    ul {
      list-style: none;
      margin:0;
      padding:0;
      li {
        padding-left: 45px;
        position: relative;
        i {
          position: absolute;
          top:5px;
          width: 32px;
          height: 43px;
          left:0px;
          &.check {
            background:url("../images/check@2x.png") top left no-repeat;
            background-size:100%;
          }
          &.call {
            background:url("../images/call@2x.png") top left no-repeat;
            background-size:100%;
          }
        }
      }
    }
  }
  @media (max-width: 992px){
    padding:40px 0px;
    h2 {
      font-size:36px;
      line-height: 40px;
      margin-top:10px;
    }
    .btn-black {
      text-transform: uppercase;
      width:100%;
      display: inline-block;
      margin-bottom: 20px;
      text-align:left;
    }
    .box {
      padding:30px 15px;
    }
  }
}
