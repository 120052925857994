.page {
  .page-banner {
    height: 500px;
    background-size:cover!important;
    background-position:center center!important;
    margin-bottom:100px;
    position: relative;
    @media (max-width: 767px){
      height: 300px;
      margin-bottom:60px;
      &:before {
        display: none;
      }
      &.bands1, &.bands2, &.bands3 {
        &:after {
          top: 0px!important;
          left: 50%!important;
          margin-left: -390px!important;
          width: 270px!important;
          height: 300px!important;
          transform: skewX(-30deg)!important;
        }
      }
    }
    &:after {
      content:'';
      background: #4AF0BF;
      position: absolute;
    }
    &:before {
      content:'';
      position: absolute;
      background: #2be3e8;
    }
    .container {
      position: relative;
    }
    h1 {
      top:200px;
      left:0px;
      background: rgba(255,255,255,0.9);
      padding:19px 54px 21px 54px;
      font-size:48px;
      font-weight: 400;
      position: absolute;
      z-index: 2;
      min-width: 400px;
      color:#333;
      @media (max-width: 767px){
        min-width: inherit;
        width: calc(100% - 30px);
        font-size:24px;
        line-height: 29px;
        padding:20px 25px 20px 16px;
        top:150px;
        left:15px;
      }
    }
    .mac-os{
      padding: 35px 20px 20px 20px;
    }
    &.bands1 {
      &:after {
        top:100px;
        left:50%;
        margin-left:-1500px;
        width:250px;
        height:5000px;
        transform: skewX(-25deg);      }
      &:before {
        top:0px;
        left:50%;
        width:300px;
        height:1200px;
        transform: skewX(35deg);
        margin-left:820px;
      }
    }
    &.bands2 {
      overflow: inherit;
      &:after {
        top:0px;
        left:50%;
        margin-left:-730px;
        width:270px;
        height:450px;
        transform: skewX(25deg);
      }
      &:before {
        top:190px;
        left:50%;
        margin-left:315px;
        width:300px;
        height:1300px;
        transform: skewX(-25deg);
      }
    }
    &.bands3 {
      &:after {
        top:120px;
        left:50%;
        margin-left:470px;
        width:270px;
        height:4500px;
        transform: skewX(25deg);
      }
      &:before {
        top:0px;
        left:50%;
        margin-left:350px;
        width:300px;
        height:400px;
        transform: skewX(25deg);
      }
    }
  }
}
