.affiliates.page {
  .page-banner {
    @media (max-width:768px) {
      margin-bottom:0;
    }
  }
  .page-content {
    p {
      font-size:18px;
      line-height:26px;
      padding:59px 181px 63px 93px;
      background:white;
      margin-bottom:30px;
      @media (max-width:768px) {
        font-size:16px;
        line-height:20px;
        padding:46px 20px 26px 20px;
        margin-right:-15px;
        margin-left:-15px;
      }
    }
  }
  .list {
    margin-bottom:80px;
  }
  .item-affiliation {
    position:relative;
    z-index:3;
    .image {
      box-shadow:0 0 20px 0 rgba(0, 0, 0, 0.15);
      background:white;
      padding:40px 20px;
      position:relative;
      height:200px;
      /*transition:background .5s;
      &:hover {
        background: rgba(74, 240, 191, 0.2);
      }*/
      img {
        position:absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        max-width:calc(100% - 40px);
        height:auto;
        max-height:120px;
      }
      @media (max-width:768px) {
        height:150px;
      }
    }
    h4 {
      color:#4D4A4D;
      font-size:18px;
      letter-spacing:-0.28px;
      line-height:22px;
      text-align:left;
      width:100%;
      display:inline-block;
      border-bottom:1px solid #4AF0BF;
      padding:15px 0px;
      margin-bottom:30px;
    }
  }
}