.spotlight-carousel {
  position:relative;
  padding-right:0px;
  padding-left:0px;
  padding-bottom:180px;
  background:#f6f6f6; 
  //overflow:hidden;
  .wnav {
    max-width: 1560px;
    width: 100%;
    top:0px;
    z-index:8;
    margin:auto;
    height: 100%;
    left:50%;
    transform:translateX(-50%);
    position: absolute;
    @media (max-width: 992px) {
      z-index: 10;
    }
  }
  @media (max-width: 768px){
    .mob {
      background-size:cover;
      background-position: center center;
    }
  }
  .relative {
    position: relative;
    @media (max-width: 992px){
      position: inherit;
    }
  }
  .block {
    position:absolute;
    &.block-one {
      top:0px;
      left:50%;
      width:270px;
      margin-left:-1200px;
      background: #4AF0BF;
      height:2500px;
      z-index:4;
      transform: skewX(-27.5deg);
    }
    &.block-two {
      top: 0px;
        left: 50%;
        width: 250px;
        background: #2be3e8;
        height: 1900px;
        z-index: 2;
        transform: skewX(27.5deg);
        margin-left:1100px;
    }
    &.block-three {
      bottom:0px;
      width:275px;
      background: #2be3e8;
      height:275px;
      z-index:9;
      transform: skewX(27.5deg)  translateX(-50%);
      left:50%;
    }
    @media (max-width: 992px){
      &.block-one {
        width:100px;
        left:540px;
      }
      &.block-two {
        left: -280px;
        width:150px;
      }
      &.block-three {
        width:160px;
        transform:skewX(30deg);
        height: 440px;
        left:60%;
      }
    }
    @media (min-width: 767px) and (max-width: 992px){
      &.block-three {
        display: none;
      }
    }
  }
  .carousel-images {
    position: relative;   
    .owl-nav, .owl-dots {
      opacity:0!important;
      display: none;
    }
    .mob {
      height: 620px;
      background-size: cover;
    }
  } 
  .owl-dots {
    display: inline-block;
    width:100%;
    position: absolute;
    bottom:230px;
    z-index: 10;
    left:0px;
    text-align:center;
    button {
      border-radius:100px;
      width:15px;
      height: 15px;
      border:2px solid #2be3e8;
      box-shadow:0px 0px 0px 2px #fff;
      display: inline-block;
      margin:5px;
      background: transparent;
      cursor:pointer;
      outline:0px !important;
      padding:0px;
      &.active {
        background: #fff;
      }
    }
    @media (max-width: 992px){
      position: absolute;
      bottom:200px;
      left:0px;
      width: 100%;
      button {
        box-shadow:none;
        width: 15px;
        height: 15px;
        border:2px solid #f6f6f6;
        &.active {
          background: white;
        }
      }
    }
  }
  .owl-nav {
    button {
      cursor: pointer;
      position:absolute;
      top:50%;
      outline:0px!important;
      margin-top:-60px;
      z-index: 5;
      background: none;
      border:0px none;
      width: 23px;
      height: 44px;
      background-size:100%;
      background-repeat: no-repeat;
      span {
        font-size:80px;
        color: white;
        display: none;
      }
      &.owl-prev {
        left:20px;
        background-image:url("../images/arrow-white.svg");
        width: 23px;
        height: 44px;
        transform:rotate(180deg);
      }
      &.owl-next {
        right:20px;
        background-image:url("../images/arrow-white.svg");
        width: 23px;
        height: 44px;
      }
    }
    @media (max-width: 992px){
      z-index: 10;
      button {
        top:inherit;
        bottom:190px;
        span {
          color: white;
          font-size:60px;
        }
        &.owl-prev {
          left:50%;
          margin-left:-80px;
          background-size: 23px;
        }
        &.owl-next {
          left: 50%;
          margin-left:55px;
          background-size: 23px;
        }
      }
    }
  }
  .carousel-text {
    position:absolute;
    left:0px;
    top:230px;
    z-index:8;
    width:100%;
    .box-text {
      background-color: rgba(255,255,255,0.9);
      padding:60px 40px;
      text-align:left;
      h3 {
        font-size: 55px;
        letter-spacing: -1.53px;
        line-height: 60px;
        font-weight: 400;
      }
      @media (min-width: 768px) and (max-width: 992px) {
        padding:50px 30px;
        
        h3 {
          font-size:46px;
          line-height: 52px;
        }
      }
    }
    @media (max-width: 992px){
      bottom:260px;
      z-index:10;
      top:inherit;
      .box-text {
        padding:20px;
        h3 {
          font-size:24px;
          line-height: 28px;
          margin-bottom: 10px;
        }
        .btn-black {
          width: 100%;
          padding:12px 20px;
          height:48px;
          text-align:left;
        }
      }
    }
  }
}