/* Geral */
.rtl {
  font-family: "Greta Arabic Light";
  header {
    .consent-cookie {
      p {
        text-align: right;
        font-size: 14px;
      }
      button {
        letter-spacing: 0px !important;
      }
    }
    .navbar-brand {
      float:right!important;
    }
    nav.navbar {
      .navbar-toggler {
        right: inherit;
        left: 35px;
      }
      #menu-mobile-navigation {
        padding-right: 0px;
        padding-left: 35px;
      }
      ul.navbar-nav {
        &.khidmah-home {
          .language-dropdown {
            .dropdown-item {
              text-align: right;
              &.active {
                .zmdi{
                  float: left;
                  &::before {
                    content: "\f26b";
                  }
                }
              }
            }
          }
          li.nav-item {
            a.nav-link {
              i {
                margin-left:5px;
                margin-right: 0px;
              }
              &.dropdown-toggle {
                &:after {
                  margin-left: 5px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
        &.main-menu {
          li.nav-item {
            a.nav-link {
              margin-left:0px;
              margin-right: 50px;
              letter-spacing: 0px;
              @media (max-width: 1170px){
                margin-right: 10px;
              }
              &.dropdown-toggle {
                &:after {
                  margin-left: 5px;
                  margin-right: 5px;
                }
              }
            }
            .dropdown-menu {
              a {
                text-align: right;
                float:right;
                letter-spacing: 0px;
                &:before {
                  margin-left:5px;
                  content:'\f2fa';

                }
              }
            }
          }
        }
      }
    }
    nav.mobile {
      text-align: right;
      ul.language {
        li {
          a {
            padding-right: 25px;
          }
        }
      }
      ul.menu {
        li {
          a {
            padding-right: 25px;
          }
        }
      }
    }
  }
  .btn-black {
    font-family: "Greta Arabic Medium";
    letter-spacing: 0.5px;
    span {
      &:before {
        transform:rotate(180deg);
        margin-left:5px;
      }
    }
  }
  .modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .send {
            .container {
              h3 {
                font-size: 35px;
                text-align: right;
              }
             .errors {
              text-align: right;
             }
            }
          }
        }
      }
    }
  }
  footer {
    .footer-social-links {
      position: absolute;
      right: auto;
      left: 0;
      text-align: left;
      width: 350px;
      @media (max-width: 425px) {
        position: relative;
        text-align: right;
      }
      .social {
        &:first-child {
          @media (max-width: 768px) {
            margin-left: 0;
          }
          @media (max-width: 468px) {
            margin-right: 10px;
          }
          margin-left: 21px;
        }
      }
    }
    .middle {
      a.link {
        text-align: right;
        &::before {
          content: '\f2fa';
          font: normal normal normal 18px/1 'Material-Design-Iconic-Font';
          color: #4AF0BF;
          margin-left:5px;
          transform: rotate(180deg);
        }

        &:after {
          content: none;
        }
      }
      @media (max-width: 768px){
        .text-right {
          text-align:right!important;
        }
      }
    }
    .bottom {
      .text-right {
        text-align: left!important;
      }
      @media (max-width: 768px){
        text-align:right;
        .text-right {
          text-align:right!important;
        }
      }
    }
    .footer-email-subscription {
      display: inline-block;
      width: 100%;
      h5 {
        text-align: right;
      }
      .btn-black {
        width: 100%;
        font-size: 14px;
        @media (max-width: 1024px) and (min-width: 769px) {
          font-size: 11px;
        }
        span {
          text-align: right;
          &:before {
            @media (max-width: 768px) {
              top: 7px;
            }
            top:11px;
          }
        }
      }
    }
    .footer-email-title {
      text-align: right;
    }
    .footer-email-button {
      width: 280px;
      height: 48px;
      text-align: right;
      position: relative;
      span {
        margin-right: 10px;
        &:before {
          position: absolute;
          top: 25px;
          right: 10px;
        }
      }
    }
  }
  .btn-fixed {
    right: inherit;
    left:20px;
  }
}

/* Home */
.rtl.home {
  .spotlight-carousel {
    //direction:ltr;
    .carousel-text {
      .box-text {
        .item {
          text-align: right;
          h3 {
            font-size: 39px;
          }
        }
      }
    }
  }
  .facility-box {
    direction:ltr;
    .white-box-paddings {
      .text-left {
        text-align: right!important;
      }
      a {
        direction:rtl;
      }
    }
    .text-content {
      direction: rtl;
      .title {
        font-size: 36px;
      }
      .description {
        font-size: 24px;
      }
    }
  }
  .our-services {
    .owl-nav {
      button {
        &.owl-next {
          left:-50px;
          transform:rotate(180deg);
          right: inherit;
        }
        &.owl-prev {
          right:-40px;
          left: inherit;
          transform:rotate(0deg);
        }
      }
      @media (max-width: 767px){
        button {
          &.owl-next {
            left:10px;
            right: inherit;
          }
          &.owl-prev {
            right: 10px;
            left: inherit;
          }
        }
      }
    }
    h3 {
      font-size: 36px;
    }
    .scroll2 {
      .item {
        h4 {
          a {
            text-align: right;
          }
        }
      }
    }
  }
  .career-spotlight {
    text-align: right;
    h2 {
      font-size: 36px;
    }
    .box {
      ul {
        li {
          padding-left: 0px;
          padding-right: 45px;
          i {
            left: inherit;
            right: 0px;
          }
        }
      }
    }
  }
  .employes-spotlight {
    .box {
      text-align: right;
      @media (min-width: 768px){
        ul {
          width: 100%;
          height: 120px;
          position: relative;
          li:first-child {
            position: absolute;
            top:0px;
            right: 0px;
          }
          li:last-child {
            position: absolute;
            top:0px;
            left: 0px;
          }
        }
      }
    }
  }
  .awards-recognition {
    h3 {
      font-size: 36px;
      text-align: right;
    }
    .item {
      text-align: right;
    }
    .owl-nav {
      button {
        &.owl-prev {
          right:-40px;
          left: inherit;
          background-image:url("../images/arrow-green.svg");
          transform:translateY(-50%) rotate(0deg);
        }
        &.owl-next {
          left:-40px;
          right: inherit;
          background-image:url("../images/arrow-green.svg");
          transform:translateY(-50%) rotate(180deg);
        }
      }
    }
    @media (max-width: 768px){
      .owl-dots {
        width: 100%;
        left:0px;
        transform:translateX(0);
      }
      .owl-nav {
        position: absolute;
        left:0px;width: 100%;
        z-index: 3;
        button {
          &.owl-prev {
            right:0%;
            left: inherit;
            // margin-left:55px;
            transform:translateY(0%) rotate(0);
          }
          &.owl-next {
            // margin-left:-80px;
            bottom:-42px;
            left:0%;
            right: inherit;
            transform:rotate(180deg);
          }
        }
      }
    }
  }
  .clients-affiliations {
    h3 {
      font-size: 36px;
    }
    .item-affiliation {
      h4 {
        text-align:right;
      }
    }
  }
}

/* Page banner */
.rtl {
  .page-banner {
    h1 {
      left:inherit;
      right: 0px;
      text-align: right;
      font-size: 39px;
    }
  }
}

/* About Us */
.rtl.aboutus {
  .facility-box {
    direction:ltr;
    text-align: right;
    .text-left {
      text-align: right!important;
    }
    a {
      direction:rtl;
    }
    .text-content {
      direction: rtl;
    }
  }
  .career-spotlight {
    text-align: right;
    .box {
      ul {
        li {
          padding-left: 0px;
          padding-right: 65px;
          i {
            left: inherit;
            right: 0px;
          }
        }
      }
    }
  }
}

/* Generic Page */
.rtl.generic-page {
  .facility-box {
    direction:ltr;
    text-align: right;
    .text-left {
      text-align: right!important;
    }
    a {
      direction:rtl;
    }
    .text-content {
      direction: rtl;
    }
  }
  .career-spotlight {
    text-align: right;
    .box {
      ul {
        li {
          padding-left: 0px;
          padding-right: 65px;
          i {
            left: inherit;
            right: 0px;
          }
        }
      }
    }
  }
}

/* Affiliates & Certification */
.rtl.affiliates {
  .page-content {
    p {
      text-align: right;
    }
    .item-affiliation {
      h4 {
        text-align: right;
      }
    }
  }
}

/* Careers */
.rtl.career {
  .facility-box{
    direction: ltr;
    text-align: right;
    .text-left{
      text-align: right !important;
    }
  }
  .key-facts{
    direction: ltr;
  }
  .section-title{
    text-align: right;
  }
  .featured-opportunities{
    text-align: right;
    direction: rtl;

    .zmdi-chevron-right{
      transform: rotate(180deg);
      margin-left: 8px;
      margin-right: 0;
    }
  }
  .media-filter .dropdown .dropdown-menu a.dropdown-item{
    text-align: right;
  }
  .dropdown{
    .close-icon{
      right: auto !important;
      left: 30px;
      @media (max-width: 767px){
        left: 20px;
      }
    }
  }
}

/* Contact */
.rtl.contact {
  .errors {
    text-align: right;
  }
  .intro {
    text-align: right;
    .box {
      h2 {
        font-size: 39px;
      }
    }
  }
  h3 {
    text-align: right;
  }
  .send {
    .success {
    text-align: right;
    }
    .btn-group {
      .multiselect, .multiselect-selected-text, .single{
        &,li,li a{
          font-family: "Greta Arabic Light" !important;
        }
      }
    }
  }
}

/* KHS */
.rtl.khs {
  text-align: right;
  .intro {
    .box {
      ul {
        li {
          padding-left: 0px;
          padding-right: 45px;
          i {
            left: inherit;
            right: 0px;
          }
        }
      }
    }
  }
  .list {
    .item {
      h4 {
        a {
          text-align: right;
          padding-right: 20px;
          &:before {
            left: 10px;
            right: inherit;
          }
        }
      }
    }
  }
  .list {
    .detail {
      &.arrow0 {
        .arrow {
          left:70%;
        }
      }
      &.arrow2 {
        .arrow {
          left:5%;
        }
      }
      h4 {
        font-size: 24px;
      }
      .btn-black {
        right: auto;
        left: 0;
      }
    }
  }
}

/* Media */
.rtl.media-page {
  .box-content {
    text-align: right;
    h3 {
      font-size: 36px;
      line-height: 38px;
    }
    span.category{
      font-family: "Greta Arabic Light" !important;
      letter-spacing: 2px;
    }
  }
  .zmdi-download {
    margin-left: 13px;
  }
  @media (min-width: 992px){
    .offset-lg-1 {
      margin-left: 0px!important;
      margin-right: 8.33333%!important;
    }
  }

  .download-link.mr-25{
    margin-right: 0;
    margin-left: 25px;
    .zmdi-long-arrow-right{
      margin-right: 0;
      margin-left: 13px;
      transform: rotate(180deg);
    }
  }
  .media-filter{
    a{
      font-family: "Greta Arabic Light" !important;
    }
    .dropdown-toggle::after{
      margin-left: 0;
      margin-right: .255em;
    }
    .dropdown.show{
      .dropdown-item{
        text-align: right;
      }
      .close-icon{
        right: auto;
        left: 30px;
        @media (max-width: 767px){
          right: auto;
          left: 20px;
        }
      }
    }
    .filter-item-border{
      border-right: 1px solid #4D4A4D;
      &:first-child{
        border-right: none;
      }
    }
  }
}

/* Error */
.rtl.error {
  .page-content {
    .box {
      text-align: right;
      a {
        margin-right: 0;
      }
    }
  }
}

/* Awards & REcognition */
.rtl.awards-recognition-page {
  .page-content {
    p {
      padding:59px 93px 63px 181px;
      text-align:right;
      @media (max-width:768px) {
        padding:46px 20px 26px 20px;
      }
    }
    .item-affiliation {
      h4 {
        text-align: right;
      }
    }
  }
}

/* Facility Management Solutions */
.rtl.facility-management-solutions {
  text-align: right;
  .intro {
    .box {
      ul {
        li {
          padding-left: 0px;
          padding-right: 45px;
          i {
            left: inherit;
            right: 0px;
          }
        }
      }
    }
  }
  .list {
    .item {
      h4 {
        a {
          text-align: right;
          &:before {
            left: 10px;
            right: inherit;
          }
        }
      }
    }
  }
  .list {
    .detail {
      &.arrow0 {
        .arrow {
          left:70%;
        }
      }
      &.arrow2 {
        .arrow {
          left:5%;
        }
      }
    }
  }
}

/* Board of Directors */
.rtl.board-of-directors{
  text-align: right;
  .job{
    padding-left: 18px;
    padding-right: 0 !important;
  }
}

/* Contact Us */
.rtl.contact{
  .btn-group {
    .multiselect{
      padding: 0 12px 0 0;
    }
    .dropdown-toggle {
      &::after {
        right: auto;
        left: 14px;
      }
      .multiselect-selected-text{
        img{
          margin-right: 0;
          margin-left: 4px;
        }
      }
    }
  }
  .error-message{
    text-align: right;
    font-family: "Greta Arabic Light";
  }
  .number-group{
    direction: ltr;
    *{
      direction: rtl;
    }
  }
  .submit-col{
    text-align: left !important;
  }
}

/* Article */
.rtl.article{
  font-family: "Greta Arabic Light";
  .article-category{
    font-family: "Greta Arabic Light" !important;
    letter-spacing: 2px !important;
  }
  p{
    font-family: "Greta Arabic Light";
  }
  .back-button{
    direction:rtl;
    text-align: right;
    span{
      font-family: "Greta Arabic Light";
    }
    span:before{
      transform: rotate(180deg);
    }
  }
}


/* Careers Details */
.rtl.careers-details {
  text-align: right;
  direction: rtl;
  
  .career-section {
    font-family: "Greta Arabic Light";
  }
  .career-sections { 
    .career-section {
      h2 {
        font-family: "Greta Arabic Light" !important;
      }
      h3 {
        font-family: "Greta Arabic Light" !important; 
      }
    } 
  }
  .career-sections .career-section ul li:before {
    margin-right: 0;
    margin-left: 15px;
  }
  .back-button{
    a {
      font-family: "Greta Arabic Light" !important;
    }
    span:before{
      transform: rotate(180deg);
    }
  }
}
.rtl {
  .social-wall {
    direction: ltr;
    .juicer-feed {
      .feed-item {
        .j-text {
          .j-message{
            direction: rtl;
            text-align: right;
          }
        }
      }
    }
  }
  .j-overlay{
    direction: rtl;
    .j-navigate{
      right: auto !important;
      left: 5px;
      display: flex;
      > a{
        transform: rotate(180deg);
        margin: 0 4px;
      }
    }
    .j-message{
      text-align: right !important;
    }
    a.juicer-button{
      float: right !important;
    }
    .j-meta{
      display: flex !important;
      text-align: right;
    }
    .j-meta a{
      margin-left: 10px;
    }
    .image, .instagram-wrapper{
      float: right !important;
    }
  }
  .career-spotlight{
    text-align: right;
    direction: rtl;
  }

  #apply-now-modal .modal-content .modal-header h5{
    font-family: "Greta Arabic Light";
  }

  #apply-now-modal .modal-content .modal-body form {
    input, textarea {
      font-family: "Greta Arabic Light";
    }
  }
}