.page.contact {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }
  .page-content {
    margin-bottom: 0px;
    .box {
      padding:30px;
      background: white;
    }
  }
  .intro {
    h3 {
      color: #4D4A4D;
      font-size: 28px;
      line-height: 36px;
      a {
        font-size: 28px;
      }
    }
    h2 {
      color: #4D4A4D;
      font-size: 48px;
      line-height: 53px;
      margin-top:15px;
    }
    h4 {
      color: #4D4A4D;
      font-size: 30px;
      font-weight: 500;//bold
      line-height: 40px;
    }
    p {
      color: #4D4A4D;
      font-size: 18px;
      line-height: 26px;
    }
    .tel a {
      display: inline;

    }

    a, label {
      display: inline-block;
      width:100%;
      font-size:18px;
      font-weight: 500;//bold
      margin-bottom: 5px;
      color:#4d4a4d;
      i {
        color: #4AF0BF;
        display: inline-block;
        width: 22px;
      }
      a{
        display: inline;
        pointer-events: none;
        margin-bottom: 0px;
      }
      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }
  }
  section.intro {
    padding-bottom:120px;
    overflow: hidden;
    position: relative;
        &:after {
        content:'';
        background: #4AF0BF;
        position: absolute;
          bottom:0px;
          left:-170px;
          width:270px;
          height:450px;
          transform: skewX(25deg);
        }
    .box {
      background: white;
      padding:50px;
      position: relative;
      z-index: 3;
    }
  }
  .send {
    background:#2BE3E8;
    padding:80px 0px;
    .box {
      background: white;
      padding: 40px 60px;
      @media (max-width: 767px){
        padding:20px;
      }
      @media (max-width: 767px){
        .g-recaptcha {
          margin-bottom: 15px;
        }
      } 
    }
  }
  .error-message {
    color: #d7283d;
    font-family: Tajawal;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
    min-height: 14px;
  }
  .btn-group {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    > button {
      display: block;
      width: 100%;
    }
    .dropdown-toggle {
      &::after {
        color: #000;
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        vertical-align: initial;
      }
    }
    .multiselect {
      height: 50px;
      line-height: 50px;
      border-radius: 0;
      border: 1px solid #999;
      background-color: #FFFFFF;
      font-family: Tajawal;
      font-size: 16px;
      color: #495057;
      box-shadow: none;
      padding: 0 0 0 12px;
      text-align: left;
      transition: .3s all;
      font-weight: 400;
      &.error {
        border: 1px solid #D0021B;
      }

      .multiselect-selected-text{
        display: flex;
        align-items: center;
        img{
          width: 15px;
          height: auto;
          margin-right: 4px;
        }
      }

      &.active{
        .multiselect-selected-text{
          color: #333;
        }
      }
    }

    ul.single {
      border: 1px solid;
      border-color: #999;
      width: 100%;
      margin-top: -1px;
      border-radius: 0;
      color: #333333;
      font-family: Tajawal;
      font-size: 16px;
      line-height: 19px;
      padding: 0;
      max-height: 400px;
      overflow-y: auto;

      li {
        input {
          display: none;
        }

        &.active:not(.disabled) {
          a {
            label {
              background-color: rgba(2,105,120,0.2);
              font-weight: bold;
              pointer-events: none;
            }
          }
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        a {
          color: #333333;
          font-family: Tajawal;
          font-size: 16px;
          line-height: 19px;
          transition: .2s all;
          cursor: pointer;
          text-decoration: none;
          &:hover {
            background-color: rgba(2,105,120,0.2);
            font-weight: bold;
          }
          @media screen and (max-width: $break-sm){
            font-size: 14px;
            line-height: 1;
          }

          label {
            margin: 0;
            display: flex;
            padding: 24px;
            align-items: center;
            img{
              width: 15px;
              height: auto;
              margin-right: 4px;
            }
            &:hover {
              background-color: rgba(2,105,120,0.2);
              font-weight: bold;
              cursor: pointer;
            }
            @media screen and (max-width: $break-sm){
              padding: 15px 24px;
            }
          }
        }
      }
    }
  }
  .btn-group.show {
    .dropdown-toggle {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  .number-group{
    display: flex;
    align-items: start;
    .btn-group{
      width: 180px;
      margin-right: -1px;
      .multiselect-selected-text{
        font-size: 16px;
        font-family: Tajawal;
        color: #333;
      }
    }
  }
  .g-recaptcha {
    > div {
      border-radius: 3px;
      border: 1px solid transparent;
      transition: .2s;
    }
    &.error {
      > div {
        border-color: #D0021B;
      }
    }
  }
  #contact-us-form-anchor{
    position: relative;
    top: -160px;
    visibility: hidden;
  }
}
.send {
  h3 {
    color: #4D4A4D;
    font-size: 48px;
    line-height: 53px;
    margin-bottom: 30px;
  }
  .errors {
    font-weight: bold;
    color:#d7283d;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
  }
  .form-control[type=text],.form-control[type=email],.form-control[type=number] {
    margin-bottom: 20px;
    border: 1px solid #999999;
    border-radius:0px;
    height: 50px;
  }
  textarea.form-control {
    margin-bottom: 20px;
    border: 1px solid #999999;
    border-radius:0px;
    height: 50px;
    height: 120px;
  }
  .form-control.error,iframe.error {
    border:1px solid #d7283d!important;
  }
  h5 {
    color: #4D4A4D;
    font-size: 28px;
    line-height: 36px;
  }
  .success {
    display: none;
  }
  .error-msg {
    display: none;
  }
}
