.our-values{
  .facility-box{
    .white-box .text-content{
      h2{
        font-size: 28px;
      }
      .notes{
        font-weight: 500;
      }
    }
  }
  .image-aboutus-box-right{
    @media screen and (max-width: $break-lg) {
      height: auto;
    }
  }
  .image-aboutus-box-left{
    @media screen and (max-width: $break-lg) {
      height: auto;
    }
  }
}