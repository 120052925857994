@import "../node_modules/material-design-iconic-font/scss/material-design-iconic-font.scss";

@import "../node_modules/placeholder-loading/src/scss/placeholder-loading.scss";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/owl.carousel/dist/assets/owl.carousel";
@import url('https://fonts.googleapis.com/css?family=Tajawal:300,400,500,700,900'); 
@import url('https://fonts.tptq-arabic.com/WF-000341-000085.css'); 