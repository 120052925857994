.clients.page {
  .list {
    .box {
      padding:15px;
      background-color: #FFFFFF;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
      margin-bottom: 30px;
      display: inline-block;
      width: 100%;
      height: 130px;
      position: relative;
      img {
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        max-width: calc(100% - 30px);
        height: auto;
        max-height: 100px;
      }
      a {
        display: inline-block;
        width:100%;
      }
      &:hover {
        background-color: #d6fbf0;  
      }
    }
  }
}