.page.board-of-directors{
  .facility-box{
    .white-box-paddings{
      padding: 65px 90px 80px 90px;
      @media screen and (max-width: $break-lg) {
        padding: 30px 20px 35px 20px;
      }
    }
  }
  .list{
    .row.only-one{
      .col-md-4{
        margin: 0 auto;
      }
      .detail .arrow{
        left: 35%;
      }
    }
    .detail .row{
      .col-lg-8{
        flex: 0 0 100%;
        max-width: 100%;
        h4{
          display: none;
        }
      }
      .col-lg-4{
        display: none;
      }
    }
    .item h4 a{
      font-weight: 400;
      .job{
        padding-right: 18px;
        font-weight: normal;
        display: block;
        font-size: 18px;
        color: #4d4a4d;

        @media all and (max-width: 767px){
          font-size: 15px;
        }
      }
    }
  }
  .block {
    position:absolute;
    &.block-three {
      top:-250px;
      left:0px;
      width:250px;
      height:3000px;
      background: #4AF0BF;
      z-index:2;
      transform: skewX(30deg);
    }
    @media (max-width: $break-md){
      &.block-three {
        top:1180px;
        left:-20px;
        width:150px;
        height: 760px;
        transform: skewX(20deg);
      }
    }
    @media (max-width: $break-sm){
      &.block-three {
        top:1350px;
        left:-20px;
        width:150px;
        height: 760px;
        transform: skewX(20deg);
      }
    }
  }
  .container-bod-list{
    max-width: 1110px;
  }
}
