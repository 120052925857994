.btn-black {
  height: 48px;
  padding:12px 20px;
  border-radius: 2px;
  background-color: #4D4A4D;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 16px;//17px
  color: white;
  font-weight:700;//900
  text-transform:uppercase;
  span{
    line-height: 24px;
    &:before{
      background-image: url("/images/arrow-green.svg");
      background-repeat: no-repeat;
      background-size: 13px 24px;
      width: 20px;
      height: 24px;
      content:"";
      display: inline-block;
    }
  }
  .mac-os{
    line-height: 30px;
  }
  &:hover {
    background-color: #4AF0BF;
    color: #333333;
    span{
      &:before{
        background-image: url("/images/arrow-white.svg");
      }
    }
  }
  @media (max-width: 992px){
    padding:12px 10px;
  }
}

.btn-fixed {
  position: fixed;
  bottom:20px;
  right: 20px;
  font-size:12px;
  height: 35px;
  padding:5px 10px;
  z-index: 11;
  i {
    font-size:26px;
    line-height: 20px;
  }
}
/*.send{
  .btn-black {
    div{
      position: relative;
      display: inline-block;
      top: -5px;
    }
  }
}
*/
form{
  .btn-black{
    @media (max-width: 767px){
      width: 100%;
    }
  }
}