.awards-recognition {
  background:#f6f6f6;
  .bg {
    background:url("../images/awards.png") -60px bottom no-repeat #f6f6f6;
    background-size:45%;
    background-blend-mode:multiply;
    padding:100px 0px;
    width:100%;
    display:inline-block;
    @media (min-height: 767px) and (max-width: 992px){
      background-image: none;
    }
  }
  .box {
    padding:20px;
    box-shadow:0 2px 10px 0 rgba(0, 0, 0, 0.3);
    background:white;
    padding:35px 70px;
    position:relative;
    .owl-carousel.owl-drag .owl-item {
      padding: 2px;
    }
    .owl-nav {
      button {
        position:absolute;
        top:50%;
        outline:0px !important;
        transform:translateY(-50%);
        background-repeat:no-repeat;
        background-size:100%;
        &.owl-next {
          right:-40px;
          background-image:url("../images/arrow-green.svg");
          width:20px;
          height:44px;
        }
        &.owl-prev {
          left:-40px;
          background-image:url("../images/arrow-green.svg");
          width:20px;
          height:44px;
          transform:translateY(-50%) rotate(180deg);
        }
        span {
          font-size:60px;
          display:none;
        }
      }
    }
    .owl-dots {
      display:inline-block;
      position:absolute;
      bottom:-20px;
      width:100%;
      text-align:center;
      right: 0px;
      button {
        border-radius:100px;
        width:10px;
        height:10px;
        border:0px solid #ddd;
        box-shadow:0px 0px 0px 2px #ddd;
        display:inline-block;
        margin:5px;
        outline:0px !important;
        &.active {
          background:#ddd;
        }
      }
    }
    .item {
      margin-top:15px;
      .text-center {
        position: relative;
        img {
          position: absolute;
          top:50%;
          left:50%;
          transform: translateX(-50%) translateY(-50%);
          max-width: calc(100% - 20px);
          @media (max-width: 767px){
            position: inherit;
            left:inherit;
            top:inherit;
            transform: none;
            max-height: 130px;
            width: auto;
            display: inline-block;
          }
        }
      }
    }
  }
  h3 {
    color:#4D4A4D;
    font-size:48px;
    line-height:57px;
    margin-bottom:30px;
    font-weight:300; //400
  }
  h4 {
    color:#4D4A4D;
    font-size:16px;
    line-height:19px;
    font-weight:900; //500
  }
  .box {
    p {
      font-size:32px;
      line-height:38px;
      a {
        color:#4D4A4D;
        text-transform:inherit;
        text-decoration:underline;
        letter-spacing:0px;
      }
    }
  }
  @media (max-width:768px) {
    background:url("../images/awards.png") -150px bottom no-repeat #f6f6f6;
    background-size:110%;
    background-blend-mode:multiply;
    .bg {
      background:none;
    }
    h3 {
      font-size:36px;
      text-align:center;
      line-height:40px;
    }
    h4 {
      line-height:40px;
      margin-top:15px;
    }
    .box {
      padding:15px 15px 60px 15px;
      .owl-nav {
        button {
          top:inherit;
          bottom:-45px;
          transform:none;
          &.owl-next {
            right:0%;
            // margin-left:55px;
          }
          &.owl-prev {
            // margin-left:-80px;
            bottom:-42px;
            left:0%;
            transform:rotate(180deg);
          }
        }
      }
      .owl-dots {
        display:inline-block;
        position:absolute;
        bottom:-34px;
        width:calc(100% - 60px);
        text-align:center;
        left:50%;
        transform:translateX(-50%);
        button {
          border-radius:100px;
          width:10px;
          height:10px;
          border:2px solid #fff;
          box-shadow:0px 0px 0px 2px #ddd;
          display:inline-block;
          margin:5px;
          &.active {
            border:2px solid #ddd;
            background:#ddd;
          }
        }
      }
    }
  }
}
.awards-recognition-page.page {
  .page-banner {
    @media (max-width:768px) {
      margin-bottom:0;
    }
  }
  .page-content {
    margin-bottom: 0;
    p {
      font-size:18px;
      line-height:26px;
      padding:59px 181px 63px 93px;
      background:white;
      margin-bottom:30px;
      @media (max-width:768px) {
        font-size:16px;
        line-height:20px;
        padding:46px 20px 26px 20px;
        margin-right:-15px;
        margin-left:-15px;
      }
    }
  }
  .item-affiliation {
    position:relative;
    z-index:3;
    .image {
      box-shadow:0 0 20px 0 rgba(0, 0, 0, 0.15);
      background:white;
      padding:40px 20px;
      position:relative;
      height:200px;
      /*transition:background .5s;
      &:hover {
        background: rgba(74, 240, 191, 0.2);
      }*/
      img {
        position:absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        max-width:calc(100% - 40px);
        height:auto;
        max-height:120px;
      }
      @media (max-width:768px) {
        height:150px;
      }
    }
    h4 {
      color:#4D4A4D;
      font-size:18px;
      letter-spacing:-0.28px;
      line-height:22px;
      text-align:left;
      width:100%;
      display:inline-block;
      border-bottom:1px solid #4AF0BF;
      padding:15px 0px;
      margin-bottom:30px;
      min-height:75px;
    }
  }
}
