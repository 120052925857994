.home {
  h3 {
    color: #4D4A4D;
    font-size: 48px;
    line-height: 57px;
    margin-bottom:30px;
    font-weight: 300;//400
    position: relative;
    z-index: 3;
  }
  .our-services {
    background: #F6F6F6;
    text-align: center;
    padding: 40px 0px;
    position:relative;
    overflow: hidden;
    &:after {
      content:'';
      top:0px;
      left:50%;
      width:300px;
      background: #4AF0BF;
      height:100%;
      z-index:2;
      transform: skewX(-35deg);
      position: absolute;
      margin-left:-720px;
      @media (max-width: 768px){
        width: 150px;
        transform:skewX(-28deg);
        left:180%;
      }
    }
    h2 {
      font-size: 36px;
      margin: 20px 0px;
      position: relative;
      z-index: 3;
      @media (max-width: 768px){
        display: none;
      }
    }
    .owl-dots {
      display: inline-block;
      width:100%;
      margin-top:20px;
      button {
        border-radius:100px;
        width:12px;
        height: 12px;
        border:2px solid #f6f6f6;
        box-shadow:0px 0px 0px 2px #ccc;
        display: inline-block;
        margin:5px;
        outline:0px!important;
        &.active {
          box-shadow:0px 0px 0px 2px #4AF0BF;
          background: #4AF0BF;
        }
      }
      @media (max-width: 767px){
        position: absolute;
        bottom:90px;
        left:0px;
        width: 100%;
        button {
          box-shadow:none;
          width: 10px;
          height: 10px;
          box-shadow:0px 0px 0px 2px #fff;
          border:2px solid transparent;
          &.active {
            border:2px solid #666;
            box-shadow:0px 0px 0px 2px #fff;
            background: white;
          }
        }
      }
    }
    .owl-nav {
      button {
        position:absolute;
        top:50%;
        outline:0px!important;
        margin-top:-60px;
        background-size:100%;
        background-repeat: no-repeat;
        span {
          font-size:80px;
          display: none;
        }
        &.owl-prev {
          left:-50px;
          background-image:url("../images/arrow-gray.svg");
          width: 23px;
          height: 44px;
          transform:rotate(180deg);
        }
        &.owl-next {
          right:-40px;
          background-image:url("../images/arrow-gray.svg");
          width: 23px;
          height: 44px;
        }
      }
      @media (max-width: 767px){
        button {
          span {
            color: white;
            font-size:60px;
          }
          &.owl-prev {
            left:10px;
            background-image:url("../images/arrow-white.svg");
          }
          &.owl-next {
            right: 10px;
            background-image:url("../images/arrow-white.svg");
          }
        }
      }
    }
    .scroll2 {
      z-index: 3;
      position: relative;
      .contain-image-zoom{
        position: relative;
          overflow: hidden;
          @media (max-width: 768px) {
            height: 180px;
            overflow: hidden;
          }
      }
      .item {
        background: white;
        &:hover {
          h3 {
            a {
              font-weight: 700;//900
            }
          }
        }
        h4 {
          margin: 0;
          padding: 0;
          display: inline-block;
          width: 100%;
          min-height: 90px;
          text-align: left;
          letter-spacing: -0.2px;
          font-size: 20px;
          font-weight: 300;//400
          line-height: 24px;
          a {
            width: 100%;
            min-height: 90px;
            display: inline-block;
            font-size: 20px;
            color: #4D4A4D;
            padding: 20px 20px;
          }
          @media (max-width: 767px) {
            min-height: 60px;
            a {
              min-height: 60px;
            }
          }
        }
      }
    }
  }
  .zoom-effect{
    max-width: 100%;
    transition: all 0.3s;
  }
  .contain-image-zoom:hover .zoom-effect{
    transform: scale(1.2);
  }
}
