.bg-grey{
  background-color: #F7F6F6;
}
.bg-white{
  background-color: #FFFFFF;
}
a, a:hover{
  text-decoration: none;
}
.media-page{
  .wrapper{
    position: relative;
  }

  .block {
    position:absolute;
    &.block-one {
      top:277px;
      left:580px;
      width:250px;
      height:1220px;
      background: #4AF0BF;
      z-index:2;
      -webkit-transform: skewX(30deg);
      transform: skewX(30deg);
    }
    &.block-two {
      top: 0px;
      right: 300px;
      width: 250px;
      height: 520px;
      background: #2be3e8;
      z-index: 2;
      -webkit-transform: skewX(30deg);
      transform: skewX(30deg)
    }
    &.block-three {
      bottom:200px;
      left:50%;
      width:250px;
      height:800px;
      background: #2be3e8;
      z-index:2;
      -webkit-transform: skewX(-30deg);
      transform: skewX(-30deg);
      margin-left:-580px;
    }
    @media (max-width: $break-md){
      &.block-one {
        width: 150px;
        height: 550px;
        left: 85px;
        -webkit-transform: skewX(15deg);
        transform: skewX(15deg);
      }
      &.block-two {
        width:150px;
        left: 175px;
        -webkit-transform: skewX(15deg);
        transform: skewX(15deg);
      }
      &.block-three {
        left:100px;
        bottom:540px;
        width:150px;
        height: 760px;
        -webkit-transform: skewX(-15deg);
        transform: skewX(-15deg);
      }
    }
  }
  footer {
    z-index: 3;
    position: relative;
  }

  .page-banner{
    margin-bottom: 50px;
  }

  .media-filter{
    text-align: center;
    margin-bottom: 50px;
    .filter-item-border{
      border-right: 1px solid #4D4A4D;
      &:last-child{
        border-right: none;
      }
    }
    .filter-item{
      font-size: 24px;
      line-height: 29px;
      font-family: Tajawal;
      color: #4d4a4d;
      text-decoration: none;
      display: inline-block;
      padding: 4px 30px;
      position: relative;
      cursor: pointer;

      &:HOVER, &.active{
        font-weight: bold;
      }
      @media (max-width: 767px){
        padding: 4px 20px;
        font-size: 17px;
      }
    }
    .dropdown{
      display: inline-block;
      .close-icon{
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        display: none;
        @media (max-width: 767px){
          right: 20px;
        }
      }
      &.show{
        font-weight: bold;
        .close-icon{
          display: block;
        }
        .dropdown-toggle::after{
          opacity: 0;
        }
      }
      .dropdown-menu{
        border: 1px solid #cbcbcb;
        border-radius: 0;
        a.dropdown-item{
          font-size: 18px;
          color: #4d4a4d;
          font-family: Tajawal;
          padding: 8px 24px;

          &.active, &:ACTIVE{
            font-weight: bold;
            background: #f8f9fa;
          }
        }
      }
    }
  }
}

.box{
  color: #4D4A4D;

  h3, .download-link{
    font-weight: 500;//700
    line-height: 33px;
    font-size: 30px;
  }
  p{
    font-weight: 400;//500
    line-height: 26px;
    font-size: 18px;
  }
  .download-link{
    line-height: 22px;
    font-size: 18px;
    text-decoration: none;
    color: #4D4A4D;
    span{
      font-weight: bold;
    }
    &.mr-25{
      margin-right: 25px;
    }
  }
  .download-link{
    text-decoration: none;
  }

  .download-link .zmdi{
    color: #4AF0BF;
    font-size: 20px;
    line-height: 25px;
    margin-right: 13px;
  }
}
.box-image{
  padding-left: 0rem;
  padding-right: 0rem;
  overflow: hidden;
  &:hover {
    img {
      transform:scale(1.1);
    }
  }
  @media (max-width: $break-lg){
    padding-left: 15px;
    padding-right: 15px;
  }

  img{
    transition: transform .5s;
    min-width: 100%;
    object-position: center;
    object-fit: cover;
    max-height: 200%;
    min-height: 100%;
  }
}
.box-with-image,  .box-without-image{
  padding-left: 0rem;
  padding-right: 0rem;
  @media (max-width: $break-lg){
    padding-left: 15px;
    padding-right: 15px;
  }
  .box-content{
    min-height: 252px;
    width: 100%;

    .download-links{
      display: flex;
    }
  }
  a:hover span {
    text-decoration: underline;
  }

  .category{
    font-family: Tajawal;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 24px;
    height: 24px;
    text-transform: uppercase;
    display: inline-block;
    //padding: 0 12px;
    //background: #2BE3E8;
    color: #333;
    margin-bottom: 10px;
  }
}
.box-without-image{
  .box-content{
    padding: 40px 90px;
    @media (max-width: $break-lg){
      padding: 40px 30px;
    }
  }
}
.box-with-image{
  .box-content{
    padding: 40px 30px;
  }
}
