.page.khs {
  .intro {
    .white {
      background:white;
    }
    .gray {
      background-color: #E8E8E8;
    }
    .box {
      padding: 50px;
      @media(max-width: 767px){
        padding: 20px;
      }
      @media (max-width: 991px){
        padding: 40px 20px 20px 20px;
      }
      .btn-black {
        display: inline-block;
        width: 100%;
        height: auto;
      }
      h4 {
        font-size: 18px;
        font-weight: bold;
      }
      h3 {
        color: #4D4A4D;
        font-size: 28px;
        line-height: 36px;
        margin-bottom:30px;
      }
      p {
        color: #4D4A4D;
        font-size: 18px;
        line-height: 26px;
      }
      ul {
        list-style: none;
        margin:0;
        padding:0;
        li {
          padding-left: 45px;
          position: relative;
          i {
            position: absolute;
            top:2px;
            width: 32px;
            height: 43px;
            left:0px;
            &.check {
              background:url("../images/check@2x.png") top left no-repeat;
              background-size:100%;
            }
            &.call {
              background:url("../images/call@2x.png") top left no-repeat;
              background-size:100%;
            }
          }
        }
      }
    }
  }
  .list {
    .one-element{
      justify-content:center;
      .arrow{
        left:calc(50% - 12px) !important;
      }
    }
    h2 {
      font-size: 36px;
      letter-spacing: -1px;
      line-height: 60px;
      text-align: center;
      margin:40px 0px;
    }
    .detail {
      background-color: #FFFFFF;
      box-shadow: 0 0 10px 0 rgba(62,74,84,0.17);
      padding:40px 60px;
      margin-bottom:30px;
      position: relative;
      margin:0px 20px 30px 20px;
      width: 100%;
      &.arrow1 {
        .arrow {
          left:35%;
        }
      }
      &.arrow2 {
        .arrow {
          left:70%;
        }
      }
      .arrow {
        box-shadow: 0 0 10px 0 rgba(62,74,84,0.17);
        width: 25px;
        height: 25px;
        position: absolute;
        background: white;
        top:-12px;
        left:20px;
        transform: rotate(45deg);
        &:after {
          position: absolute;
            content: '';
            background: white;
            bottom: -5px;
            height: 111%;
            width: 222%;
            transform: rotate(-45deg);
            left: 0px;
        }
      }
      h4 {
        font-size: 36px;
        letter-spacing: -0.36px;
        line-height: 43px;
      }
      p {
        color: #4D4A4D;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 10px;
      }
      a {
        right: 0;
        position: absolute;
        top:50%;
        transform:translateY(-50%);
      }
      @media (min-width: 767px) and (max-width: 992px){
        padding:40px 60px 40px;
        .btn-black {
          margin-top:10px;
          position: inherit;
        }
      }
      @media (max-width: 767px){
        margin:25px 0px;
        padding:20px 30px 30px 30px;
        h4 {
          display: none;
        }
        p {
          font-size:14px;
          line-height: 20px;
        }
        .arrow, .arrow1, .arrow2 {
          left:50%!important;
          margin-left:-10px;
        }
        .btn-black {
          margin-top:10px;
          position: inherit;
          width: 100%;
        }
      }
    }
    .item {
      background: white;
      margin-bottom:30px;
      &.active {
        h4 {
          background: rgba(74,240,191,0.3);
          a {
            &:before {
              content:'\f136';
            }
          }
        }
      }

      p {
        display: none;
      }

      &:hover {
        h3 {
          a {
            font-weight: 900;
          }
        }
      }

      a {
        display: inline-block;
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
      h4 {
        @media (max-width: 426px) {
          padding: 0px;
        }
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: left;
        letter-spacing: -0.2px;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        position: relative;
        margin-bottom: -10px;
        min-height: 74px;
        display: flex;
        align-items: center;
        padding: 10px 0 10px 0;
        a {
          @media (max-width: 426px) {
            font-size: 16px;
          }
          width: 100%;
          display: inline-block;
          font-size: 20px;
          color: #4D4A4D;
          padding: 0px 40px 0px 20px;
          // position: absolute;
          // top:50%;
          // left:0px;
          width: 100%;
          // transform:translateY(-50%);
          &:before {
            content:'\f278';
            position: absolute;
            top:50%;
            transform:translateY(-50%);
            right: 20px;
            color: #4AF0BF;
            font: normal normal normal 14px/1 'Material-Design-Iconic-Font';;
            font-size: 24px;
            line-height: 26px;
          }
        }
      }
      @media (max-width: 767px){
        margin-bottom: 16px;
        &.active {
          background: rgba(74,240,191,0.3);

          h4 {
            background: transparent;
          }
        }
        .link-img {
          display: inline-block;
          width: 100px;
        }
        h4 {
          width:calc(100% - 100px);
          display: inline-block;
          position: relative;
          height: inherit;
          margin-bottom: 0px;
          float: right;
          a {
            position: absolute;
            left:0px;
            width: calc(100%);
            top:50%;
            transform:translateY(-50%);
            &:before {
              top:50%;
              transform:translateY(-50%);
            }
          }
        }
      }
    }
    .flex-right{
      display: flex;
        justify-content: flex-end;
    }
  }
}
