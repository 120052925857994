.generic-page{

    &.rtl {
      .page-content {
        .container {
          p, h3, h4 {
            text-align: right;
            direction: rtl;
          }
        }
      }
    }

    .block{
        &.block-one{
          @media screen and (max-width: 768px) {
            top: 0px;
          }
            top: 120px;
            left: 50%;
            width: 270px;
            margin-left: -888px;
            background: #4af0bf;
            height: 2500px;
            z-index: 2;
            -webkit-transform: skewX(-27.5deg);
            transform: skewX(-27.5deg);
        }
        &.block-two{
            top: 0;
            left: 50%;
            width: 250px;
            background: #2be3e8;
            height: 1900px;
            z-index: 2;
            -webkit-transform: skewX(27.5deg);
            transform: skewX(27.5deg);
            margin-left: 1100px;
        }

    }

    .page-content {
        .box {
            padding:30px;
            background: #fff;
        }

        .container {
            padding-right:0;
            padding-left:0;

            .box {
              @media screen and (max-width: 426px) {
                margin-left: 15px;
                margin-right: 15px;
                padding-right: 15px;
                padding-left: 15px;
              }
              padding-right: 75px;
              padding-left: 75px;
            }

            p {
              margin-bottom: 30px;
            }

            h3 {
              margin-bottom: 34px;
            }

            h4 {
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 0;
            }
        }
    }

    .page-banner{
      overflow: hidden;
      background-position: unset !important;
    }
}
