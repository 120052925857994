.page.article{
  @extend .aboutus;

  .page-banner{
    display: flex;
    h1{
      max-width: 600px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .article-category{
    background: #2BE3E8;
    padding: 5px 15px 3px;
    font-family: Tajawal;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 14px;
    text-transform: uppercase;
    color: #333;
    display: inline-block;
    margin-bottom: 17px;
  }
  .article-date{
    color: #333333;
    font-family: Tajawal;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 17px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .back-button{
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    padding: 20px 0;
    margin-top: 30px;
    a{
      color: #4D4A4D;
      font-family: Tajawal;
      font-size: 18px;
      font-weight: bold;
      text-transform: initial;
      display: inline-block;
      &:HOVER{
        span{
          text-decoration: underline;
        }
      }
      span{
        &:before{
          background-repeat: no-repeat;
          background-image: url(/images/arrow-green.svg);
          background-size: 13px 24px;
          width: 20px;
          height: 24px;
          content: "";
          display: inline-block;
        }
      }
    }
  }
  .white-box-paddings{
    padding: 65px 90px 80px 90px;
    @media screen and (max-width: $break-lg) {
      padding: 30px 20px 35px 20px;
    }

    .text-content{
      color: #4D4A4D;
      font-family: Tajawal;
      font-size: 18px;
      line-height: 26px;
      font-weight: 400;
    }
  }
}
