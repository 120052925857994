#subscription{
  .modal-dialog{
    max-width: 709px;

    .modal-content{
      border-radius: 0px;
      border: 0px solid rgba(0, 0, 0, 0);

      .modal-header{
        padding: 3.75rem 3.75rem 0rem 3.75rem;
        border-bottom:  0px solid rgba(0, 0, 0, 0);
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        .close{
          padding: 0rem;
          height: 20px;

          .zmdi{
            color: #4AF0BF;
            font-size: 36px;
            line-height: 22px;
          }
        }
      }
      .modal-body{
        padding: 0rem 5.625rem 5.625rem 5.625rem;
        @media screen and (max-width: $break-sm) {
          padding: 0rem 1.625rem 1.625rem 1.625rem;
        }

        .error-message {
          color: #D0021B;
          font-family: Tajawal;
          font-size: 18px;
          font-weight: bold;
          line-height: 22px;
          text-align: left;
          margin-bottom: 32px;
          display: none;
        }

        .send{
          background: #FFF;
          padding: 0px;
          margin-top: 0px;

          .box{
            padding: 0px;
          }
        }
      }
    }
  }
}

/*Forms customization are located on contact.scss*/
