.consent-cookie {
    font-family: Tajawal;
    display: flex;
    align-items: center;
    background: #4d4a4d;
    width: 100vw;
    color: #fff;
    padding-top: 10px;
    z-index: 800;
    position: fixed;
    height: 4.625 rem;
    top: 0;

    .cookie-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        a {
            color: #fff;
            text-decoration: underline;
            background-color: transparent;
            text-align: center;
        }

        .accept-cookies {
            height: 48px;
            padding: 10px 20px;
            background-color: #4d4a4d;
            font-size: 16px;
            letter-spacing: 2px;
            line-height: 16px;
            color: #fff;
            font-weight: 700;
            border-color: #fff;
        }
    }
}

.consent-cookie.hidden { display: none; }

.consent-cookie.hidden ~ .wrapper .navbar .navbar-nav.main-menu .nav-item .dropdown-menu { top: 140px;}
.consent-cookie.hidden ~ .wrapper { 
    padding-top:0px!important;
}

.consent-cookie.hidden ~ .wrapper nav.mobile {
    top:120px;
}

.consent-cookie ~ .wrapper { 
    @media (max-width: 992px)
    {
        padding-top: 156px!important;
    }
}
