$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;

#apply-now-modal {
  @media screen and (min-width: $break-md){
    .modal-dialog {
      top: 50%;
      transform: translateY(-50%);
      margin-top: -30px;
      margin-bottom: 0;
      max-width: 1140px;
    }
  }

  .modal-content {
    border-radius: 0;
    border: none;
    padding: 70px 90px;
    @media screen and (max-width: $break-sm){
      padding: 30px 20px;
    }

    .modal-header {
      border: none;
      padding: 0;
      margin: 0;

      h5 {
        color: rgba(51,51,51,0.6);
        font-family: Tajawal;
        font-size: 36px;
        margin-bottom: 10px;
        @media screen and (max-width: $break-sm){
          font-size: 22px;
        }
      }
      
      button {
        background-color: transparent;
        text-align: center;
        box-shadow: none;
        padding: 0;
        outline: none;
        border: none;
        margin: -2rem -3rem;
        cursor: pointer;
        opacity: 1;
        @media screen and (max-width: $break-sm){
          margin: 0;
        }

        i {
          color: #4AF0BF;
          font-size: 36px;
          padding: 0;
          margin: 0;
          @media screen and (max-width: $break-sm){
            font-size: 26px;
          }
        }
      }
    }

    .modal-body {
      padding: 0;
      form {
        padding: 0;
        .error-message {
          color: #D0021B;
          font-family: Tajawal;
          font-size: 18px;
          font-weight: bold;
          line-height: 22px;
          text-align: left;
          margin-bottom: 32px;
          display: none;
        }

        .btn-group {
          width: 100%;
          position: relative;

          > button {
            display: block;
            width: 100%;
          }

          .dropdown-toggle {
            &::after {
              color: #65003C;
              position: absolute;
              right: 14px;
              top: 50%;
              transform: translateY(-50%);
              vertical-align: initial;
            }
          }

          .multiselect {
            height: 50px;
            line-height: 50px;
            border-radius: 0;
            border: 1px solid #E3E3E3;
            background-color: #FFFFFF;
            font-family: Tajawal;
            font-size: 16px;
            color: rgba(51, 51, 51, 0.5);
            box-shadow: none;
            padding: 0 0 0 24px;
            text-align: left;
            transition: .3s all;
            @media screen and (max-width: $break-sm){
              height: 40px;
              line-height: 40px;
              font-size: 14px;
            }
            &.error {
              border: 1px solid #D0021B;
            }
          }

          
        }

        .uploadFile, input {
          display: block;
          width: 100%;
          height: 50px;
          line-height: 50px;
          padding: 0 25px;
          border: 1px solid #E3E2DD;
          font-family: Tajawal;
          color: #333333;
          box-shadow: none;
          transition: .3s;
          outline: none;
          @media (max-width: $break-sm) {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
          }
          &:focus {
            border-color: #999999;
          }
          &.error {
            border-color: #D0021B;
          }

        }

        .uploadFile{
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;
          overflow: hidden;
          input{
            position: absolute;
            opacity: 0;
            top: 0;
            right: 0;
            width: 200%;
            height: 100%;
            z-index: 9;
            cursor: pointer;
          }
          .label{
            cursor: pointer;
            position: relative;
            z-index: 1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #333;
            &.active{
              opacity: 1;
            }
            span{
              cursor: pointer;
            }
          }

        }

        textarea {
          display: block;
          width: 100%;
          min-height: 120px;
          line-height: 1;
          padding: 15px 25px;
          border: 1px solid #E3E2DD;
          font-family: Tajawal;
          color: #333333;
          box-shadow: none;
          transition: .3s;
          outline: none;
          &:focus {
            border-color: #999999;
          }
          &.error {
            border: 1px solid #D0021B;
          }
        }

        .g-recaptcha {
          @media (max-width: $break-sm) {
            transform:scale(0.77);
            transform-origin:0 0;
          margin-bottom: -25px;
          }

          > div {
            border-radius: 3px;
            border: 1px solid transparent;
            transition: .2s;
          }

          &.error {
            > div {
              border-color: #D0021B;
            }
          }
        }

        button[type="submit"] {
          margin-top: 13px;
          width: 100%;
        }
      }

      .send-enquiry-success, .send-enquiry-error {
        display: none;

        h4 {
          color: #4D4A4D;
          font-size: 28px;
          font-family: Tajawal;
        }

        p {
          font-family: Tajawal;
          font-size: 18px;
          margin: 0;
          color: #333333;
        }
      }


      .signup-success, .signup-error {
        display: none;
        margin: 10px 0 0;
        padding: 0;

        h4 {
          color: #4D4A4D;
          font-size: 28px;
          font-family: Tajawal;
          @media screen and (max-width: $break-sm) {
            font-size: 22px;
          }
        }

        p {
          font-family: Tajawal;
          font-size: 18px;
          margin: 0;
          color: #333333;
          @media screen and (max-width: $break-sm) {
            font-size: 16px;
          }
        }
      }
    }
  }
}