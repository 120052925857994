.clients-affiliations {
  padding:80px 0px;
  text-align:center;
  overflow:hidden;
  position: relative;
  background: white;
  @media (max-width: 768px){
    padding-top:50px;
  }
  &:after {
    content:'';
    bottom:0px;
    right:-150px;
    width:250px;
    background: #4AF0BF;
    height:40%;
    z-index:2;
    transform: skewX(-35deg);
    position: absolute;
    @media(max-width: 768px){
      right:-300px;
    }
  }
  &:before {
    content:'';
    top:0px;
    left:-130px;
    width:300px;
    background: #2be3e8;
    height:70%;
    z-index:2;
    transform: skewX(-40deg);
    position: absolute;
    @media(max-width: 768px){
      left:-50%;
      transform:skewx(-25deg);
      width: 120px;
    }
  }
  h3 {
    color: #4D4A4D;
    font-size: 46px;
    letter-spacing: -0.25px;
    font-weight: 300;//400
    line-height: 60px;
    text-align:center;
    margin-bottom:20px;
    position: relative;
    z-index: 5;
    @media (max-width: 768px){
      margin-top:40px;
    }
  }
  .item-affiliation {
    position: relative;
    z-index:3;
    .image {
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
      background: white;
      padding:40px 20px;
      position: relative;
      height: 200px;
      /*transition:background .5s;
      &:hover {
        background: rgba(74, 240, 191, 0.2);
      }*/
      img {
        position: absolute;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        max-width: calc(100% - 40px);
        height: auto;
        max-height:120px;
      }
      @media (max-width: 768px){
        height: 150px;
      }
    }
    h4 {
      color: #4D4A4D;
      font-size: 18px;
      letter-spacing: -0.28px;
      line-height: 22px;
      text-align:left;
      width:100%;
      display: inline-block;
      border-bottom:1px solid #4AF0BF;
      padding:15px 0px;
      margin-bottom:80px;
    }
  }
  .item-client {
    z-index: 4;
    padding:15px;
    background-color: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
    height: 130px;
    position: relative;
    img {
      position: absolute;
      top:50%;
      left:50%;
      transform:translateX(-50%) translateY(-50%);
      max-width: calc(100% - 30px);
      height: auto;
      max-height: 100px;
    }
    &:hover {
      background-color: #d6fbf0;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
    }
  }
  @media (max-width: 768px){
    h3 {
      font-size:42px;
      line-height: 44px;
      position: relative;
      z-index: 2; 
    }
    .item-affiliation {
      h4 {
        font-size:14px;
        line-height: 18px;
        margin-bottom:20px;
      }
    }
  }
}
