$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;

.social-wall {
  padding: 40px 0;
  position: relative;
  h3 {
    color: #4D4A4D;
    font-size: 46px;
    letter-spacing: -0.25px;
    font-weight: 300;
    line-height: 60px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    z-index: 5;
    @media (max-width: $break-md){
      margin-top: 40px;
      font-size: 42px;
      line-height: 44px;
      position: relative;
      z-index: 2;
    }
  }
  .juicer-feed {
    z-index: 3;
    .j-paginate {
      display: none;
    }
    .feed-item {
      .j-text {
        background: #4af0bf !important;
        .j-message {
          p, a {
            color: #000 !important;
          }
        }
        .j-meta a {
          color: #000;
        }
      }
    }
  }
}
.social-wall:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 250px;
  width: 300px;
  background: #2be3e8;
  height: 50%;
  z-index: 0;
  transform: skewX(30deg);
  @media (max-width: $break-xl) {
    left: 200px;
    width: 250px;
    height: 40%;
  }
}
.social-wall:after {
  content: "";
  position: absolute;
  top: 0;
  right: 222px;
  width: 300px;
  background: #4af0bf;
  height: 50%;
  z-index: 0;
  transform: skewX(-35deg);
  @media (max-width: $break-xl) {
    right: 197px;
    width: 250px;
    height: 40%;
  }
  @media (max-width: $break-lg) {
    right: 185px;
  }
  @media (max-width: $break-md) {
    right: 25px;
  }
}
.j-overlay .j-close {
  top: 15px!important;
  right: 5px!important;
}