.page.campaign {
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	    /* display: none; <- Crashes Chrome on hover */
	    -webkit-appearance: none;
	    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}

	input[type=number] {
	    -moz-appearance:textfield; /* Firefox */
	}
	.page-banner {
		&:after {
			margin-left: -1401px;
		}
	}
	p.intro, div.intro p {
		background: white;
		padding:60px;
		color: #4D4A4D;
		font-size: 28px;
		line-height: 36px;
	}
	.page-content {
		margin-bottom: 0px;
	}
	.send {
		background:#2BE3E8;
		padding:80px 0px;
		margin-top:120px;
		.box {
			background: white;
			padding: 40px 60px;
			@media (max-width: 767px){
				padding:20px;
			}
			@media (max-width: 767px){
				.g-recaptcha {
					margin-bottom: 15px;
				}
			}
		}
		.errors {
			font-weight: bold;
			color:#d7283d;
			display: inline-block;
			width: 100%;
			margin-bottom: 10px;
		}
		.error-message {
			font-weight: bold;
			color:#d7283d;
			display: none;
			width: 100%;
			margin-bottom: 10px;
		}
		h3 {
			color: #4D4A4D;
			font-size: 48px;
			line-height: 53px;
			margin-bottom: 30px;
		}
		.form-control[type=text],.form-control[type=email],select.form-control {
			margin-bottom: 20px;
			border: 1px solid #999999;
			border-radius:0px;
			height: 50px;
		}
		textarea.form-control {
			margin-bottom: 20px;
			border: 1px solid #999999;
			border-radius:0px;
			height: 50px;
			height: 120px;
		}
		.form-control.error {
			border:1px solid #d7283d!important;
		}
	}
}