
.page.careers-details {
  .facility-box .white-box-paddings{
    padding: 80px 63px;
    margin-bottom: 50px;

    @media all and (max-width: 767px){
      padding: 40px 20px;
    }
  }
  .career-sections{
    padding-top: 15px;
    color: #4D4A4D;
    font-family: Tajawal;
    font-size: 18px;
    line-height: 26px;
    .career-section{
      padding: 20px 0;
      h2{
        color: #4D4A4D;
        font-family: Tajawal;
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
      }
      h3{
        color: #4D4A4D;
        font-family: Tajawal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }
      .description-text{
        padding: 20px 0 0;
      }
      .descriptions{
        margin-bottom: 14px;
        .description{
          display: flex;
          align-items: center;
          .label{
            min-width: 121px;
            max-width: 121px;
            width: 121px;
            font-weight: 400;
          }
        }
      }
      ul{
        padding: 0;
        margin: 0;
        list-style: none;
        li{
          padding: 0 0 0 10px;
          margin: 0;
          &:before {
            content:"·";
            font-size:30px;
            vertical-align:middle;
            line-height:20px;
            margin-right: 15px;
          }
        }
      }
      p:last-child{
        margin-bottom: 0;
      }
      .btn{
        margin: 80px 0;
        padding-left: 40px;
        padding-right: 40px;

        @media all and (max-width: 767px){
          margin: 40px 0;
        }
      }
    }
  }
  .back-button{
    border-top: 1px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
    padding: 20px 0;
    margin-top: 0px;
    margin-bottom: -20px;
    a{
      color: #4D4A4D;
      font-family: Tajawal;
      font-size: 18px;
      font-weight: bold;
      text-transform: initial;
      display: inline-block;
      &:HOVER{
        span{
          text-decoration: underline;
        }
      }
      span{
        &:before{
          background-image: url(/images/arrow-green.svg);
          background-repeat: no-repeat;
          background-size: 13px 24px;
          width: 20px;
          height: 24px;
          content: "";
          display: inline-block;
        }
      }
    }
  }
}