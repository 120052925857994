.aboutus{
  .wrapper{
    position: relative;
    background:#f6f6f6;
  }
  .career-spotlight {
    .box {
      ul {
        li {
          padding-left:65px;
          i.call {
            width: 50px;
            height: 70px;
          }
        }
      }
    }
  }
  .facility-box {
    margin-top:80px;
    .white-box {
      .text-content {
        min-width: inherit;
      }
    }
  }
  .block {
    position:absolute;
    &.block-one {
      top:0px;
      left:75px;
      width:250px;
      height:570px;
      background: #4AF0BF;
      z-index:2;
      transform: skewX(30deg);
    }
    &.block-two {
      top: 0px;
      right: 120px;
      width: 250px;
      height: 1500px;
      background: #2be3e8;
      z-index: 2;
      transform: skewX(-30deg)
    }
    &.block-three {
      top:-250px;
      left:0px;
      width:250px;
      height:3000px;
      background: #4AF0BF;
      z-index:2;
      transform: skewX(30deg);
    }
    @media (max-width: $break-md){
      &.block-one {
        width: 150px;
        height: 500px;
        left: 5px;
        transform: skewX(15deg);
      }
      &.block-two {
        width:150px;
        left: 565px;
        top: 30px;
        height: 1100px;
        transform: skewX(-15deg);
      }
      &.block-three {
        top:1180px;
        left:-20px;
        width:150px;
        height: 760px;
        transform: skewX(20deg);
      }
    }
    @media (max-width: $break-sm){
      &.block-one {
        width: 150px;
        height: 500px;
        left: -17px;
        transform: skewX(15deg);
      }
      &.block-two {
        width:150px;
        left: 175px;
        top: 180px;
        height: 1100px;
        transform: skewX(-15deg);
      }
      &.block-three {
        top:1350px;
        left:-20px;
        width:150px;
        height: 760px;
        transform: skewX(20deg);
      }
    }
  }
  footer {
    z-index: 3;
    position: relative;
  }
}
.image-aboutus-box{
  overflow: hidden;
  img{
    @media (max-width: $break-lg){
      object-fit: cover;
      width: 100%;
      object-position: center;
      max-height: 250px;
    }
  }
}
.image-aboutus-box-right{
  position: relative;
  margin-top: 66px;
  margin-left: -90px;
  z-index: 3;
  @media screen and (max-width: $break-lg) {
    margin-top: 0px;
    margin-left: 0px;
    height: 250px;
  }
}
.image-aboutus-box-left{
  position: relative;
  margin-top: 66px;
  margin-right: -90px;
  z-index: 3;
  @media screen and (max-width: $break-lg) {
    margin-top: 0px;
    margin-right: 0px;
    height: 250px;
  }
}
.white-box-paddings-right{
  padding: 60px 187px 60px 90px;
  @media screen and (max-width: $break-lg) {
    padding: 30px 20px 35px 20px;
  }
}
.white-box-paddings-left{
  padding: 60px 90px 60px 187px;
  @media screen and (max-width: $break-lg) {
    padding: 30px 20px 35px 20px;
  }
}
