.employes-spotlight {
  background: url("../images/employee.png");
  background-size:cover;
  padding:100px 0px;
  position:relative;
  overflow:hidden;
  &:after {
    content:'';
    top:0px;
    right:-70px;
    width:250px;
    background: #4AF0BF;
    height:100%;
    z-index:2;
    transform: skewX(35deg);
    position: absolute;
    @media (max-width: 768px){
      right: -320px;
    }
  }
  &:before {
    content:'';
    bottom:0px;
    left:50%;
    width:250px;
    background: #2be3e8;
    height:50%;
    z-index:2;
    transform: skewX(-20deg);
    position: absolute;
    @media (max-width: 768px){
      left:0px;
      width: 150px;
    }
  }
  .box {
    background: white;
    padding:50px 100px;
    position: relative;
    z-index: 3;
    h5 {
      font-size: 30px;
      font-weight: 500;//bold
      line-height: 26px;
    }
    h4 {
      font-size:85px;
      margin-bottom:10px;
      line-height: 85px;
      font-weight: 500;
        /*background: linear-gradient(to right, #4af0bf, #2be3e8,#4af0bf, #2be3e8,#4af0bf, #2be3e8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;*/
        
        color:#4AF0BF;
        background: inherit;
        -webkit-background-clip: inherit;
        -webkit-text-fill-color: #4AF0BF;
        display: inline-flex;
        width: 100%;
    }
    span {
      font-size: 20px;
      font-weight: 500;//bold
      line-height: 24px;
      text-transform: uppercase;
    }
    ul {
      list-style:none;
      margin:0;
      padding:0;
      display: inline-block;
      width:100%;
      li {
        width:60%;
        float:left;
        &:last-child {
          width:40%;
        }
      }
      .mac-os{
        padding-top: 20px;
      }
    }
  }
  @media (max-width: 768px){
    .box {
      padding:40px 15px;
      h4 {
        font-size:105px;
        line-height:105px;
        margin-bottom: 0px;
      }
      ul {
        li {
          width: 100%!important;
          margin-bottom: 20px;
        }
      }
    }
  }
}
