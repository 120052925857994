body,html {
  .btn-group .dropdown-toggle::after{
    color: #4af0bf !important;
  }
  
  font-family: 'Tajawal';
  .home {
    .wrapper.np {
      background: #f6f6f6;
    }
  }
  .scroll {
    display: inline-block;
    width: 100%;
    overflow: hidden;
  }
  .wrapper {
    //max-width: 1600px;
    margin:auto;
    //overflow: hidden;
    padding-top:140px;
    &.np {
      padding-top:0px!important;
    }
    @media (max-width: 992px) {
      padding-top:120px;
    }
  }
  header {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    z-index: 15;
    background: white;
    .w100 {
      width: 100%;
    }
    .wrapper {
          max-width: 1600px;
      padding-top:84px!important;
      background: white!important;
      overflow: inherit!important;
    }
    .top {
      background: #4d4d4d;
      font-size:16px;
      padding:3px 0px;
      i {
        color:#4AF0BF;
      }
      p {
        margin-bottom:0px;
        color: white;
        a {
          text-decoration: none;
          color: white;
        }
      }
    }
    nav.navbar {
      height: 140px;
      position:relative;
      @media (min-width: 768px) and (max-width: 1170px){
        ul.navbar-nav.khidmah-home {
          li.nav-item {
            a.nav-link {
              min-width: 150px!important;
            }
          }
        }
        ul.navbar-nav.main-menu {
          li.nav-item {
            a.nav-link {
              margin-left:12px!important;
            }
          }
        }
      }
      @media (min-width: 1170px){
        ul.navbar-nav.khidmah-home {
          .btn-black {
            font-size:18px;
            height: 48px;
            /*line-height: 22px;
            i {
              font-size:22px;
              vertical-align: text-top;
            }*/
          }
        }
      }
      @media (max-width: 992px) {
        height: 90px;
        .navbar-toggler {
          position: absolute;
          top:35px;
          right: 20px;
          width:30px;
          height: 30px;
          padding:0px;
          outline:0px!important;
          border-radius:0px;
          span {
            float:left;
            width: 100%;
            height: 4px;
            margin-bottom:7px;
            background: #4AF0BF;
            transition:transform .5s, margin .5s;
          }
          &.active {
            span:nth-child(1){
              transform:rotate(45deg);
              margin:-1px 0px;
            }
            span:nth-child(2){
              transform:rotate(-45deg);
              margin:-3px 0px;
            }
            span:last-child {
              display: none;
            }
          }
        }
      }
      #menu-mobile-navigation {
        padding-right:35px;
        padding-top:13px;
      }
      .navbar-brand {
        padding-left:35px;
        float:left;
        padding:30px 0px 30px 45px;
        @media (max-width: 992px){
          padding:15px 0px 15px 15px;
          width:140px;
          img {
            width: 100%;
          }
        }
      }
      .dropdown-toggle::after {
        content:'\f2f9';
        font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
            color:#4AF0BF;
            border:0px none;
            font-size:18px;
            vertical-align: middle;
      }
      ul.navbar-nav {
        &.khidmah-home {
          margin-bottom: 0px;
          flex-basis: 100%;
          flex-wrap: wrap;
          justify-content: flex-end;
          position: relative;
          li.dropdown {
            .dropdown-menu {
              display: none!important;
            }
            .show{
              display: block!important;
            }
          }
          li.nav-item {
            a.nav-link {
              color: #4D4A4D;
              text-align: center;
              font-size: 16px;
              line-height: 22px;
              min-width: 190px;
                  font-weight: 500;//700
                  padding:19px 0px;
                  i {
                    color:#4AF0BF;
                    vertical-align: middle;
                    font-size:20px;
                    margin-right: 5px;
                  }
            }
                  a.khidmah-btn {
                      text-transform: uppercase;
              background-color: #4D4A4D;
              color: #FFFFFF;
              min-width: 190px;
              font-size: 18px;
              height: 60px;
              width: 249px;
              border-radius: 0 2px 2px 0;
              background-color: #4D4A4D;
              padding:18px 0px;
              @media (max-width:1280px ){
                width:190px;
              }
            }
          }
        }

        &.main-menu {
          li.dropdown {
            .dropdown-menu {
              display: none!important;
              max-width: 1600px;
              margin:auto;
              left:50%!important;
              transform:translateX(-50%);
            }
            &:hover {
              .dropdown-menu {
                display: block!important;
              }
            }
          }

          li.nav-item {
            position:inherit;
            &:hover {
              .nav-link {
                border-bottom:5px solid #4AF0BF;
                color: #4AF0BF;
              }
            }
            &.separator {
              display: flex;
              align-items: center;
              padding: 0 20px;
              height: 43px;
              &::before {
                content: '';
                display: block;
                width: 1px;
                background-color: #FFFFFF;
                height: 24px;
              }
            }

            > a.nav-link {
                  text-transform: uppercase;
              color: #4D4A4D;
              font-size: 20px;
                  font-weight:700;//900
              padding-left: 0px;
              margin-left:65px;
              padding-right: 0px;
              padding-bottom:17px;
              border-bottom:5px solid white;
              letter-spacing: 2px;
              @media (max-width:1280px ){
                margin-left:50px;
              }
            }
            .dropdown-menu {
                      position: fixed;
              top:calc(140px + 84px);
              left:0px;
              width:100%;
              padding:50px 120px;
              border-radius:0px;
              border:0px none;
              box-shadow:4px 4px 15px 0px rgba(0,0,0,0.1);
              @media (max-width:1400px ){
                padding:50px 80px;
              }
              @media (max-width:1280px ){
                padding:50px 0px;
                .dropdown-item {
                  font-size:16px;
                }
              }
              .dropdown-item {
                float:left;
                width:25%;
                clear:inherit;
                font-weight:700;//900
                font-size:16px;
                &:before {
                  content:'\f2fb';
                  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
                  filter:rotate(90deg);
                  font-size:16px;
                  margin-right:5px;
                  font-weight:bold;
                  color:#4AF0BF;
                }
                &:hover {
                  background-color: #F4F4F4;
                }
              }
            }

            /*&:last-child {
              > a.nav-link {
                font-size: 16px;
              }
            }*/
          }
        }

        .khidmah-home {
          .dropdown-menu{
              border-bottom:5px solid #4AF0BF;
          }
        }
        .dropdown-menu {
          border-top:5px solid #4AF0BF;
          top:57px;
          left:0px;
          width:100%;
          padding:8px 0px 8px 0px;
          border-radius:0px;
          border:0px none;
          box-shadow:4px 4px 15px 0px rgba(0,0,0,0.3);

          .dropdown-item {
            color: #4D4A4D;

            &:active,
            &:focus,
            &.active {
              background-color: #FFF;
              font-weight: 700;
              .zmdi{
                position: relative;
                float: right;
                line-height: 24px;
                &::before {
                  content: "\f26b";
                  color: #4AF0BF;
                  font-size: 20px;
                }
              }
            }

            &:hover {
              background-color: #FFF;
              font-weight: 700;
            }
          }
          /*@media (max-width:1400px ){
            padding:50px 80px;
          }
          @media (max-width:1280px ){
            padding:50px 0px;
            .dropdown-item {
              font-size:16px;
            }
          }*/
        }
      }
    }
    nav.mobile {
      position: fixed;
      top:260px;
      height: calc(100% - 120px);
      width: 100%;
      background: white;
      z-index:9;
      overflow: auto;
      display: none;
      ul {
        list-style:none;
        margin:0;
        padding:0;
        display: inline-block;
        width:100%;
        a {
          color:#4D4D4D;
          text-transform:uppercase;
          font-size:20px;
          font-weight:bold
        }
        .dropdown {
          position: relative;
          &:after {
            content: '\f2f9';
            font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
            color: #4AF0BF;
            border: 0px none;
            font-size: 22px;
            vertical-align: middle;
            position: absolute;
            top:13px;
            right: 20px;
          }
          .dropdown-link {
            display: inline-block;
            width: 100%;
            text-decoration:none!important;
          }
        }
        li {
          padding:10px 15px;
        }
        ul {
          display: none;
          li {
            padding:5px;
            a {
              font-size:16px;
              font-weight: normal;
              text-transform: inherit;
            }
          }
        }
      }
      .language {
        border-top:1px solid #eee;
        background: #f6f6f6;
      }
      .menu {
        margin:15px 0px 5px 0px;
      }
      .bottom {
        padding:0px 15px;
        a.phone, a.email {
          color:#4d4d4d;
          display: inline-block;
          width:100%;
          margin-bottom:5px;
          i {
            color:#4AF0BF;
          }
        }
        a.btn-black {
          width:100%;
          text-transform: uppercase;
          margin-bottom:15px;
          text-align:left;
        }
      }
    }
  }

  footer {
    position: relative;
    background: white;
    z-index: 5;
    .footer-social-links {
      position: absolute;
      right: 0;
      text-align: right;
      top: 0;
      width: 220px;
      @media (max-width: 768px) {
        margin-top: 20px;
      }
      @media (max-width: 425px) {
        position: relative;
        text-align: left;
      }
      .social {
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .red-brand {
      background-color: #D68F7A;
      color: white;
      padding:15px 0px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 19px;
      display: inline-block;
      width:100%;
      p {
        float:left;
        display: inline-block;
        text-align:center;
        width:100%;
        margin-bottom:0px;
        a {
          color: white;
        }
        b {
          font-weight: 700;
        }
      }
      .mac-os{
        padding-top: 13px;
      }
      b {
        vertical-align: sub;
        font-size: 30px;
        line-height: 36px;
      }
      @media (max-width: 768px) {
        font-size:12px;
        line-height: 16px;
        padding:15px;
        b {
          display: inline-block;
          width: 100%;
        }
      }
    }
    .middle {
      padding:50px 0px 0px 0px;
      h5 {
        color: #4D4A4D;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.23px;
        line-height: 17px;
        text-transform: uppercase;
      }
      a.social {
        color:#333;
        font-size:24px;
        margin-left:21px;
        color:#333;
      }
      a.link {
        width:100%;
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        text-transform: inherit;
        color:#333;
        margin-bottom:10px;
        white-space: nowrap;
        &:before {
          content: '\f2fb';
          font: normal normal normal 18px/1 'Material-Design-Iconic-Font';
          color: #4AF0BF;
          margin-right:5px;
        }
        &:hover span {
          text-decoration: underline;
        }
      }
      @media (min-width: 768px) and (max-width: 1200px){
        .btn-black {
          font-size:12px;
          line-height: 22px;
        }
      }
      @media (max-width: 768px){
        h5 {
          font-size:12px;
        }
        a.social {
          font-size:30px;
          color:#4d4a4d;
        }
        .btn-black {
          width: 100%;
          text-transform: uppercase;
          text-align:left;
          padding:7px 10px;
          height: 38px;
          margin-bottom:30px;
        }
        a.link {
          margin-bottom:15px;
          font-size: 15px;
        }
        .text-right {
          text-align:left!important;
          .social {
            margin-left: 0px;
            margin-right: 30px;
          }
        }
      }
    }
    .bottom {
      border-top:1px solid #f6f6f6;
      padding:10px 0px 10px 0px;
      margin-top:30px;
      a {
        text-transform: inherit;
        color:#333;
        letter-spacing:0px;
        margin-left:15px;
        text-transform: uppercase;
        &:hover {
          text-decoration: underline;
        }
      }
      @media (max-width: 768px) {
        font-size:12px;
        border-top:1px solid #eee;
        margin-top:20px;
        padding-bottom:50px;
        .container {
          padding:0px;
        }
        a {
          display: inline-block;
          width:100%;
          font-weight: bold;
          margin-left:0px;
        }
        p {
          margin-bottom: 10px;
        }
      }
    }
  }

  .page {
    .wrapper {
      background: #f6f6f6;
    }
    .page-content {
      min-height: 500px;
      margin-bottom: 100px;
      position: relative;
      z-index: 2;
    }
  }

  .no-scroll{
    position:fixed;
  }
}
