$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;
.showmob{
  display: none;
  @media screen and (max-width: $break-lg) {
    display: block;
  }
}
.hidemob{
  display: block;
  @media screen and (max-width: $break-lg) {
    display: none;
  }
}
.max-width{
  max-width: 1080px;
  margin: 0 auto;
  z-index: 3;
}
.facility-box{
  position: relative;
  z-index: 10;
  a{
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;

    .khidmah-btn{
      color: #FFFFFF;
      line-height: 17px;
      background-color: #4D4A4D;
      border-radius: 2px;
      padding: 16px 24px;
      max-width: 300px;
      @media screen and (max-width: $break-lg) {
        font-size: 12px;
      }

      .zmdi{
        color: #4AF0BF;
        font-size: 36px;
        line-height: 18px;
        margin-right: 6px;
      }
    }
  }
  .white-box{
    background: #FFFFFF;
    z-index: 2;

    .text-content{
      color: #4D4A4D;
      @media screen and (max-width: $break-lg) {
        min-width: 100%;
      }

      .title{
        font-size: 46px;
        font-weight: 500;//700
        line-height: 50px;
        letter-spacing: -0.25px;
        @media screen and (max-width: $break-lg) {
          font-size: 24px;
          line-height: normal;
        }
      }
      .description{
        font-size: 28px;
        line-height: 36px;
        font-weight: 400;//500
        letter-spacing: -0.88px;
        @media screen and (max-width: $break-lg) {
          font-size: 20px;
          line-height: normal;
        }
      }
      h2{
        font-weight: 400;
      }
      .notes{
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;//500
        @media screen and (max-width: $break-lg) {
          font-size: 16px;
          line-height: normal;
        }
      }
    }
  }
  .white-box-paddings{
    padding: 65px 187px 80px 90px;
    @media screen and (max-width: $break-lg) {
      padding: 30px 20px 35px 20px;
    }
  }
  .image-facility-box{
    margin-top: -80px;
    margin-left: -90px;
    z-index: 7;
    @media screen and (max-width: $break-lg) {
      margin-top: 0px;
      margin-left: 0px;
    }
    @media (max-width: 992px){
      margin-top:-150px;
      height: 250px;
    }
    img{
      @media (max-width: $break-lg){
        object-fit: cover;
        width: 100%;
        object-position: center;
        max-height: 250px;
      }
    }
  }
}
