.error.page {
  .box {
    padding:70px;
    background: white;
    p {
      color: #4D4A4D;
      font-size: 28px;
      line-height: 36px;
    }
    a {
      margin-top:10px;
      margin-right: 20px;
    }
  }
  .page-banner {
    &:after {
      margin-left: -1400px;
    }
  }
  .page-content {
    min-height: 320px;
  }
}
