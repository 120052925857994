.page.career {
  .section-title{
    color: #4D4A4D;
    font-family: Tajawal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
  }
  .content-internal{
    h2{
      margin-bottom: 20px;
    }
  }
  .facility-box .white-box .text-content .description{
    font-weight: 400;
  }

  .key-facts{
    padding: 40px 0 90px;
    background: url(../images/amenities-and-key-facts.png) center no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;

    @media all and (max-width: 767px){
      padding-bottom: 30px;
    }

    .section-title{
      margin-bottom: 20px;

      @media all and (min-width: 992px){
        margin-left: 90px;
      }
    }
    .box-wrapper{
      @media all and (max-width: 767px){
        position: relative;
        margin-bottom: 20px;
        padding: 0 30px;
      }
      .box{
        background: #fff;
        padding: 80px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .number{
          color: #4D4A4D;
          font-family: Tajawal;
          font-size: 100px;
          font-weight: bold;
          line-height: 80px;
          text-align: center;

          @media all and (max-width: 767px){
            font-size: 40px;
            line-height: 40px;
          }
        }
        .description, .text{
          color: #4D4A4D;
          font-family: Tajawal;
          font-size: 18px;
          font-weight: bold;
          line-height: 26px;
          text-align: center;
          p{
            margin: 0;
          }
          @media all and (max-width: 767px){
            font-size: 14px;
            line-height: 20px;
          }
        }
        .description{
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 10px;
        }
      }
    }

    .owl-carousel{
      .owl-stage, .owl-item{
        display: flex;
      }
      .owl-dots{
        left: 0;
        top: 100%;
        pointer-events: none;
        padding: 20px 0;
        text-align: center;
        position: absolute;
        width: 100%;
        .owl-dot{
          border: 2px solid #4D4A4D;
          margin: 0 5px;
          border-radius: 50%;
          display: inline-block;
          height: auto;
          line-height: 0;
          
          span{
            display: inline-block;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            margin: 2px;
          }
          &.active span{
            background: #4D4A4D;
          }
        }
        @media all and (max-width: 767px){
          display: none;
        }
      }
      .owl-nav{
        .owl-prev, .owl-next{
          background: url(/images/arrow-green.svg) center no-repeat;
          background-size: contain;
          width: 30px;
          height: 50px;
          position: absolute;
          font-size: 80px;
          margin: 0;
          transform: translateY(-50%);
          top: 50%;
          &::before{
            border-left-color: #65003C;
            border-top-color: #65003C;
          }
        }
        .owl-prev{
          left: -50px;
          transform: translateY(-50%) rotate(180deg);
          @media all and (min-width: 768px) and (max-width: 1199px){
            left: -30px;
          }
          @media all and (max-width: 767px){
            left: -10px;
          }
        }
        .owl-next{
          right: -50px;
          left: auto;
          @media all and (min-width: 768px) and (max-width: 1199px){
            right: -30px;
          }
          @media all and (max-width: 767px){
            right: -10px;
          }
        }
      }
    }
  }

  .featured-opportunities{
    padding: 50px 0 100px;
    position: relative;
    z-index: 1;

    .section-title{
      margin-bottom: 20px;
    }
    .white-box{
      background: #fff;
      padding: 80px 92px;
      @media all and (max-width: 767px){
        padding: 40px 30px;
      }
    }
    .jobs{
      .job-item{
        display: none;
        &.active{
          display: block;
        }
        &.inactive{
          display: none !important;
        }
      }
      .row:last-child{
        .job{
          border-bottom: 1px solid #E3E2DD;
        }
      }
    }
    .job{
      padding: 30px 0;
      border-top: 1px solid #E3E2DD;
      display: flex;
      flex-direction: column;
      height: 100%;
      .title{
        color: #4D4A4D;
        font-family: Tajawal;
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
      }
      .description{
        color: #4D4A4D;
        font-family: Tajawal;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 10px;
        p:last-child{
          margin-bottom: 0;
        }
      }
      .link{
        margin-top: auto;
        a{
          color: #4D4A4D;
          font-family: Tajawal;
          font-weight: 900;
          display: flex;
          align-items: center;
          font-size: 16px;
          letter-spacing: 2px;
          i{
            color: #4AF0BF;
            font-size: 35px;
            position: relative;
            top: 2px;
            margin-right: 8px;
          }
          span{
            padding-top: 4px;
          }
          &:HOVER{
            span{
              text-decoration: underline;
            }
          }
        }
      }
    }

    .btn-black{
      margin-top: 50px;
    }
  }

  .media-filter{
    text-align: right;
    margin-bottom: 50px;
    .filter-item-border{
      border-right: 1px solid #4D4A4D;
      &:last-child{
        border-right: none;
      }
    }
    .filter-item{
      font-size: 24px;
      line-height: 29px;
      font-family: Tajawal;
      color: #4d4a4d;
      text-decoration: none;
      display: inline-block;
      padding: 4px 30px;
      position: relative;
      cursor: pointer;

      &:HOVER, &.active{
        font-weight: bold;
      }
      @media (max-width: 767px){
        padding: 4px 20px;
        font-size: 17px;
      }
    }
    .dropdown{
      display: inline-block;
      .close-icon{
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        display: none;
        @media (max-width: 767px){
          right: 20px;
        }
      }
      &.show{
        font-weight: bold;
        .close-icon{
          display: block;
        }
        .dropdown-toggle::after{
          opacity: 0;
        }
      }
      .dropdown-menu{
        border: 1px solid #cbcbcb;
        border-radius: 0;
        a.dropdown-item{
          font-size: 18px;
          color: #4d4a4d;
          font-family: Tajawal;
          padding: 8px 24px;

          &.active, &:ACTIVE{
            font-weight: bold;
            background: #f8f9fa;
          }
        }
      }
    }
  }
}
.rtl.career.page {
  .section-title{
    font-family: "Greta Arabic Light";
  }
  .key-facts {
    .box-wrapper {
      .text, .number {
        font-family: "Greta Arabic Light";
      }
    }
  }
  .featured-opportunities{
    .title, .description, .link a {
      font-family: "Greta Arabic Light"
    }
  }
  .owl-carousel{
    .owl-nav{
      .owl-prev{
        right: -50px;
        left: auto;
        transform:translateY(-50%);
        @media(max-width: 1199px){
          right: -35px;
        }
        @media(max-width: 767px){
          right: -10px;
        }
      }
      .owl-next{
        right: auto;
        left: -50px;
        transform:translateY(-50%) rotate(180deg);
        @media(max-width: 1199px){
          left: -35px;
        }
        @media(max-width: 767px){
          left: -10px;
        }
      }
    }
  }
}